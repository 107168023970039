.edgro-logo {
    float: right;
    margin: 30px 20px 20px 0px;
}

.consent-container {
    min-height: 30vh;
    margin: 4rem auto 0 !important;
    padding: 3rem 0;
    max-width: 90%;

    .consent-content {
        margin: 5vh 2vh 2vh;
        padding: 0 !important;

        .consent-card {
            border-radius: 15px;
            box-shadow: 0px 3px 6px #00000029;
            max-width: 700px;

            .card-content {

                .user-consent-selection {
                    display: inline-flex;

                    .input-checkbox {
                        height: 25px;
                        width: 25px;
                        margin-right: 20px;
                    }
                    .consent-desc {
                        font-size: 18px; 
                        line-height: 27px;
                    }
                }
                .action-container {
                    
                    .proceed-button {
                        float: right;
                        margin-bottom: 2rem;
                        margin-top: 2rem;
                        background:  #5476FF 0% 0% no-repeat padding-box;
                    }
                }

            }
        }
    }

    .error-badge {
        margin-top: 5vh;
        padding-left: 0px !important;
    }
}



.card-message {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
}